<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('setting.create fee child rule')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <HeaderNotice ref="date_range_error_create">
                <template v-slot:error>
                  <div>既に登録された期間があります。(
                    <router-link
                      :to="{
                      name: $consts.ROUTES.ADMIN.FEE_CHILD_RULE,
                      query: {'filters.start_date.in_date_range_start': entryToSave.start_date, 'filters.end_date.in_date_range_end': entryToSave.end_date}
                      }" target="_blank">こちら
                    </router-link>
                    をご確認ください）
                  </div>
                </template>
              </HeaderNotice>
              <AppInput type="int" rules="required" name="child_rule_rate_1"
                        :label="$t('setting.fee child rule rate 1 label')"
                        v-model="entryToSave.rate_1">
                <template v-slot:append>
                  <i class="fa fa-percent"></i>
                </template>
              </AppInput>
              <AppInput type="int" rules="required" name="child_rule_rate_2"
                        :label="$t('setting.fee child rule rate 2 label')"
                        v-model="entryToSave.rate_2">
                <template v-slot:append>
                  <i class="fa fa-percent"></i>
                </template>
              </AppInput>
              <AppInput type="int" rules="required" name="child_rule_rate_3"
                        :label="$t('setting.fee child rule rate 3 label')"
                        v-model="entryToSave.rate_3">
                <template v-slot:append>
                  <i class="fa fa-percent"></i>
                </template>
              </AppInput>
              <AppInput type="int" rules="required" name="child_rule_rate_4"
                        :label="$t('setting.fee child rule rate 4 label')"
                        v-model="entryToSave.rate_4">
                <template v-slot:append>
                  <i class="fa fa-percent"></i>
                </template>
              </AppInput>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('setting.update fee child rule')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <HeaderNotice ref="date_range_error_update">
                <template v-slot:error>
                  <div>既に登録された期間があります。(
                    <router-link
                      :to="{
                      name: $consts.ROUTES.ADMIN.FEE_CHILD_RULE,
                      query: {
                        'filters.start_date.in_date_range_start': entryToEdit.start_date,
                        'filters.end_date.in_date_range_end': entryToEdit.end_date,
                        'filters.id.not_equal': entryToEdit.id
                      }
                      }" target="_blank">こちら
                    </router-link>
                    をご確認ください）
                  </div>
                </template>
              </HeaderNotice>
              <AppInput type="int" rules="required|max_value:100" name="child_rule_rate_1"
                        :label="$t('setting.fee child rule rate 1 label')"
                        v-model="entryToEdit.rate_1">
                <template v-slot:append>
                  <i class="fa fa-percent"></i>
                </template>
              </AppInput>
              <AppInput type="int" rules="required|max_value:100" name="child_rule_rate_2"
                        :label="$t('setting.fee child rule rate 2 label')"
                        v-model="entryToEdit.rate_2">
                <template v-slot:append>
                  <i class="fa fa-percent"></i>
                </template>
              </AppInput>
              <AppInput type="int" rules="required|max_value:100" name="child_rule_rate_3"
                        :label="$t('setting.fee child rule rate 3 label')"
                        v-model="entryToEdit.rate_3">
                <template v-slot:append>
                  <i class="fa fa-percent"></i>
                </template>
              </AppInput>
              <AppInput type="int" rules="required|max_value:100" name="child_rule_rate_4"
                        :label="$t('setting.fee child rule rate 4 label')"
                        v-model="entryToEdit.rate_4">
                <template v-slot:append>
                  <i class="fa fa-percent"></i>
                </template>
              </AppInput>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: this.$t('setting.fee child rule list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.FEE_CHILD_RULE_LIST,
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.FEE_CHILD_RULE_DELETE,
        }
      },
      actions: {
        editEntry: this.editEntry,
      },
      tools: {
        search: {
          reset: true,
          filters: [
            {
              name: 'id',
              type: 'not_equal',
            },
            {
              name: 'start_date',
              rules: 'required_if:end_date',
              type: 'in_date_range_start',
              label: this.$t('common.start_date'),
              // uiType: 'datepicker',
            },
            {
              name: 'end_date',
              rules: 'required_if:start_date',
              type: 'in_date_range_end',
              label: this.$t('common.end_date'),
              // uiType: 'datepicker',
            }
          ]
        }
      },
      entryToSave: {},
      entryToEdit: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {
          name: 'rate_1',
          label: this.$t('setting.fee child rule rate 1 label'),
          computed: entry => `${this.formatNumber(entry.rate_1)}%`
        },
        {
          name: 'rate_2',
          label: this.$t('setting.fee child rule rate 2 label'),
          computed: entry => `${this.formatNumber(entry.rate_2)}%`
        },
        {
          name: 'rate_3',
          label: this.$t('setting.fee child rule rate 3 label'),
          computed: entry => `${this.formatNumber(entry.rate_3)}%`
        },
        {
          name: 'rate_4',
          label: this.$t('setting.fee child rule rate 4 label'),
          computed: entry => `${this.formatNumber(entry.rate_4)}%`
        },
        {name: 'creator', label: this.$t('common.creator'), listShow: false},
        {name: 'created_at', label: this.$t('common.created at'), listShow: false},
        {name: 'updater', label: this.$t('common.updater'), listShow: false},
        {name: 'updated_at', label: this.$t('common.updated at'), listShow: false},
      ]
    }
  },
  components: {BasicTable},
  methods: {
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {}
      this.$refs.form_create_entry.reset()
      this.$refs.date_range_error_create.clear();
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {rate_1, rate_2, rate_3, rate_4} = this.entryToSave
      const res = await this.$request.post(this.$consts.API.ADMIN.FEE_CHILD_RULE_SAVE, {
        rate_1, rate_2, rate_3, rate_4
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {};
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else if (res.data?.date_error) {
          this.$refs.date_range_error_create.setError();
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      const {rate_1, rate_2, rate_3, rate_4} = this.entryToEdit
      const res = await this.$request.patch(this.$consts.API.ADMIN.FEE_CHILD_RULE_EDIT(this.entryToEdit.id), {
        rate_1, rate_2, rate_3, rate_4
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else if (res.data?.date_error) {
          this.$refs.date_range_error_update.setError();
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
      this.$refs.date_range_error_update.clear();
    },
    /* UPDATE entry end */
  }
}
</script>
